$(document).ready(function () {

    $('body').on('change', '.js-price-filter', function () {
        var price_from = $('.js-price-filter-from').val();
        var price_to = $('.js-price-filter-to').val();
        var page = $('.js-category').attr('data-page');

        $('.js-category').attr('data-min', price_from);
        $('.js-category').attr('data-max', price_to);

        GetProducts(page);
    });

    $('body').on('change', '.js-sort-by', function () {
        $('.js-sort-by').val($(this).val());
        GetProducts(0);
    });

    $('body').on('change', '.js-items-per-page', function () {
        $('.js-items-per-page').val($(this).val());
        GetProducts(0);
    });

});