$(document).ready(function () {

    $('.js-scroll-to-alternative-products').on('click', function () {
        var header_height = $('.js-sticky-header').outerHeight() + 15; // Get the height of the header
        var scroll_to = $('.js-related-products--alternative:visible').offset().top - header_height; // Subtract the header height from the top offset position of the target element

        $('html, body').animate({
            scrollTop: scroll_to
        }, 800); // Animate the scroll to the adjusted position, considering the header height
    });

    if ($('.js-variants').length > 0) {
        const container = $('.js-variants');
        const nextArrow = $('.js-next-variant');
        const prevArrow = $('.js-prev-variant');

// Function to toggle the visibility of the arrows
        const toggleArrowVisibility = () => {
            const currentScrollLeft = container.scrollLeft();
            const maxScrollLeft = container[0].scrollWidth - container.outerWidth();

            if (currentScrollLeft >= maxScrollLeft) {
                nextArrow.removeClass('visible');
            } else {
                nextArrow.addClass('visible');
            }

            if (currentScrollLeft <= 0) {
                prevArrow.removeClass('visible');
            } else {
                prevArrow.addClass('visible');
            }
        };

// Initial check
        toggleArrowVisibility();

// Event when the next arrow is clicked
        nextArrow.on('click', function () {
            const variants = container.find('.js-variant');
            const firstVariantWidth = variants.first().outerWidth(true);

            const currentScrollLeft = container.scrollLeft();
            const newScrollLeft = currentScrollLeft + firstVariantWidth;

            container.animate({
                scrollLeft: newScrollLeft
            }, 300, toggleArrowVisibility); // Check visibility after scrolling
        });

// Event when the prev arrow is clicked
        prevArrow.on('click', function () {
            const variants = container.find('.js-variant');
            const firstVariantWidth = variants.first().outerWidth(true);

            const currentScrollLeft = container.scrollLeft();
            const newScrollLeft = currentScrollLeft - firstVariantWidth;

            container.animate({
                scrollLeft: newScrollLeft
            }, 300, toggleArrowVisibility); // Check visibility after scrolling
        });

// Event when the container is manually scrolled
        container.on('scroll', toggleArrowVisibility);

// Event when the window is resized or orientation is changed
        $(window).on('resize orientationchange', function () {
            toggleArrowVisibility();
        });

    }

    $('.js-variant').on('mouseenter', function () {

        var new_src = $(this).attr('data-image');

        var slide = $('.js-faux-slide');
        var slide_img = slide.find('.js-faux-slide-image');

        slide_img.attr('src', new_src);
        slide.addClass('visible');
    }).on('mouseleave', function () {

        // On mouseout, revert to the original image and unpause the slideshow
        var slide = $('.js-faux-slide');
        var slide_img = slide.find('.js-faux-slide-image');

        slide_img.removeAttr('src');
        slide.removeClass('visible');
    });

    $('.js-anchor-link').on('click', function (e) {

        e.preventDefault();

        var ref = $(this).attr('data-ref');
        var goto = $('.js-anchor-point[data-ref=' + ref + ']');

        $('html, body').animate({
            scrollTop: goto.offset().top - $('.js-sticky-header').outerHeight() - 30
        }, 750);

    });

});
