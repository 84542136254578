$(document).ready(function () {
    var visible = 4;

    if ($(window).width() < 530) {
        visible = 1;
    }

    if ($(window).width() > 530) {
        visible = 2;
    }

    if ($(window).width() > 800) {
        visible = 3;
    }

    if ($(window).width() > 1000) {
        visible = 4;
    }

    if ($(window).width() > 1200) {
        visible = 2;
    }

    // var pauseOnType = false;
    var pauseOnType = ".js-search-products-input";

    // pause slideshow if typing in homepage postcode search bar
    $('.js-search-input').on('keyup', function () {
        if ($(this).val().length > 0) {
            $('.js-slideshow-home').cycle('pause');
        } else {
            $('.js-slideshow-home').cycle('resume');
        }
    });

    //Below replaces repeating the similar/related/etc cycle initialisations - any element with class js-WHATEVER-prod-carousel will automagiaclly be set up
    // Loop over all elements in the DOM
    $('*').each(function () {
        // Loop over each class in the element's class list
        $.each(this.classList, function (index, className) {
            // Check if the class name matches the pattern 'js-[word]-prod-carousel'
            const match = className.match(/^js-(\w+)-prod-carousel$/);

            // If a match is found, initialize the carousel
            if (match) {
                const word = match[1];
                $('.' + className).initCycle({
                    'slides': '> .js-product-' + word,
                    'pageActiveClass': 'active',
                    'fx': 'carousel',
                    'carouselVisible': visible,
                    'carouselFluid': true,
                    'allowWrap': true,
                    'timeout': 4000,
                    'pager': '+ .js-slideshow-thumbs-' + word + '-products',
                    'pagerTemplate': '<span class="related-products__pager"></span>',
                    'pauseOnHover': true
                });
            }
        });
    });


    $('.js-slideshow').initCycle({
        'slides': '> .js-slide',
        'pager': '.js-slideshow-thumbs',
        'pagerTemplate': '',
        'pageActiveClass': 'active',
        'fx': 'scrollHorz',
        'pauseOnHover': true
    });

    $('.js-slideshow-home').initCycle({
        'slides': '> .js-slide-home',
        'pager': '.js-slideshow-thumbs-home',
        'pagerTemplate': '<span class="home__pager"></span>',
        'pageActiveClass': 'active',
        'fx': 'scrollHorz',
        'pauseOnHover': pauseOnType
    });

});