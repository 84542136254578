$(document).ready(function () {

    $('.js-nav-link').on('click', function (e) {

        var li = $(this).closest('li');

        $('.js-nav-link').closest('li').removeClass('active');
        li.addClass('active');

        var nav = $(this).attr('data-nav');
        var subnav = $('.js-subnav[data-nav=' + nav + ']');

        if (!subnav.hasClass('visible')) {
            e.preventDefault();
        }

        $('.js-subnav').removeClass('visible');

        subnav.addClass('visible');

    });

    $('body').on('click', function (e) {

        if (!$(e.target).hasClass('js-nav-link')) {
            $('.js-subnav').removeClass('visible');
            $('.nav__list li').removeClass('active');
        }
    });

});