$(document).ready(function () {

    $(window).on('resize orientationchange ready load change', function () {
        if ($(window).width() < 880) {
            $('.js-collapse-this').hide();
        }else{
            $('.js-collapse-this').show();
        }
    });

    $('.js-collapse-on-mobile h3').on('click', function () {

        if ($(window).width() < 880) {

            var current = $(this).closest('.js-collapse-on-mobile');

            // menu is active
            if (current.find('.js-collapse-this').is(":visible")) {
                $('.js-collapse-on-mobile').find('.expander').removeClass('active');
                current.find('.js-collapse-this').slideUp();
            } else { // menu is inactive
                $('.js-collapse-this').slideUp();
                $('.js-collapse-on-mobile').find('.expander').removeClass('active');
                current.find('.js-collapse-this').slideToggle();
                current.find('.expander').addClass('active');
            }
        }
    })

});