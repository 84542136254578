$(document).ready(function () {


    $('.js-show-mobile-search').on('click', function (){
        $('.js-mobile-search').toggle();
        $('.js-mobile-search').find('.js-search-input').focus();
    });

    $('.js-mobile-nav-content li').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        li = $(this);

        var inner_ul = li.find('> ul');
        var outer_ul = li.closest('ul');

        if (li.hasClass('has-children')) {
            console.log('Has children');
            inner_ul.addClass('visible');
            console.log(inner_ul);
        } else if (li.hasClass('back')) {
            console.log('Is back button');
            outer_ul.removeClass('visible');
            console.log(outer_ul);
        } else {
            console.log('Is link only');
            var url = li.find('a').first().attr('href');
            location.href = url;
        }
    });

    $('.js-mobile-nav').on('click', function () {

        $('.js-mobile-nav-content ul').removeClass('visible');
        $('.js-mobile-nav-content').toggle();
        $(this).toggleClass('open');

    });


});