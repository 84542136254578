$(document).ready(function () {

    $('.js-hit-button-on-enter').on('keypress', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            return false;
        }
    });

    $('.checkout input').not('.js-required,[required],[type=radio],[type=checkbox],[type=button]').after('<span>Optional</span>');

    $('.js-checkout-step').not('.not-clickable').on('click', function () {

        if ($(this).hasClass('filled')) {

            var ref = $(this).attr('data-ref');

            var section_to_show = $('.js-checkout-section[data-ref=' + ref + ']');

            $('.js-checkout-section').hide();
            section_to_show.show();

            $('.js-checkout-step').removeClass('active');
            $('.js-checkout-step[data-ref=' + ref + ']').addClass('active');
        }

    });

    // variables, checkout steps
    var orderStep = $('.js-checkout-step[data-ref=complete]');

    // variables, checkout sections
    var deliverySection = $('.js-checkout-section[data-ref=delivery]');
    var paymentSection = $('.js-checkout-section[data-ref=payment]');
    var orderSection = $('.js-checkout-section[data-ref=complete]');

    // hide and show checkout sections
    $('.js-checkout-sign-in').on('click', function (e) {

        $('.js-login-email, .js-login-password').val('');

        // pass new user email to delivery email
        var signEmail = $('.js-new-email').val();

        var form = $(this).closest('.js-form');
        var response = form.checkRequiredFields();

        if (!response.valid) {
            dialogify("<p>Please fill in all required fields, it looks like you missed these fields...</p><ul><li>" + response.empty_fields.join('</li><li>') + "</li></ul>");
        } else if (!isValidEmailAddress(signEmail)) {
            dialogify("<p>Please ensure that the email entered is formatted correctly.</p>");
        } else {
            $('.js-checkout-section').hide();
            deliverySection.show();

            $('.js-delivery-email').val(signEmail);

            $('.js-checkout-step').removeClass('active');
            $('.js-checkout-step[data-ref=delivery]').addClass('active filled');

            $('.js-first-delivery-address-field').focus().select();

        }
    });

    $('.js-find-postcode').on('click', function () {
        var form = $(this).closest('.js-form');
        var response = form.checkRequiredFields();

        if (!response.valid) {
            dialogify("<p>Please fill in all required fields, it looks like you missed these fields...</p><ul><li>" + response.empty_fields.join('</li><li>') + "</li></ul>");
        } else {
            FindPostCode();
            // $('.js-delivery-flow').hide();
            $('.js-select-address-wrapper').show();
            $('.js-delivery-flow[section=select]').show();
        }
    });

    $('.js-checkout-go-to-payment').on('click', function (e) {
        var form = $(this).closest('.js-form');
        var response = form.checkRequiredFields();

        if (!response.valid) {
            dialogify("<p>Please fill in all required fields, it looks like you missed these fields...</p><ul><li>" + response.empty_fields.join('</li><li>') + "</li></ul>");
        } else {
            $('.js-checkout-section').hide();
            paymentSection.show();
            $('.js-checkout-step').removeClass('active');
            $('.js-checkout-step[data-ref=payment]').addClass('active filled');
            $('html, body').scrollTop(0);
        }
    });

    $('.js-pay-order').on('click', function (e) {
        $('.js-checkout-section').hide();
        orderSection.show();
        // colour checkout "steps"
        if (orderSection.is(":visible")) {
            orderStep.find('.js-checkout-step').addClass('active');
            $('html, body').scrollTop(0);
        }
    });


    // hide / show existing customer form
    $('.js-checkout-guest').on('change ready load', function () {

        if ($(this).val() == 'guest') {
            $('.js-existing-container').hide();
            $('.js-guest-container').show();
        } else {
            $('.js-existing-container').show();
            $('.js-guest-container').hide();
        }

    });


    // hide / show invoice form
    $('.js-invoice-same').click(function () {
        if ($(this).prop("checked") == true) {
            CopyDeliveryAddress();
            $('.js-inv-container').hide();
        } else if ($(this).prop("checked") == false) {
            $('.js-inv-container').show();
        }
    });

    // hide / show order reference details
    $('.js-ref-input').click(function () {
        if ($(this).prop("checked") == true) {
            $('.js-ref-container').show();
        } else if ($(this).prop("checked") == false) {
            $('.js-ref-container').hide();
        }
    });

    // hide / show customer sign up
    $('.js-new-customer').click(function () {
        if ($(this).prop("checked") == true) {
            $('.js-cust-container').show();
        } else if ($(this).prop("checked") == false) {
            $('.js-cust-container').hide();
        }
    });

    if($('.js-select-payment-method').length > 1) {
        getBasketData().then(function (basket) {
            var items = basket.Items.map(function (item, itemIndex) {
                // Create the base item object with common properties
                var itemObject = {
                    item_id: item.StockCode,
                    item_name: item.Name,
                    item_brand: item.BrandName,
                    price: item.PriceIncludingVATString,
                    quantity: item.Quantity
                };

                // Loop through each category in the item's Categories array
                item.Categories.forEach(function (category, categoryIndex) {
                    // Dynamically create the property name for categories
                    var propertyName = 'item_category' + (categoryIndex === 0 ? '' : (categoryIndex + 1));
                    // Assign the category name to the dynamically named property
                    itemObject[propertyName] = category.Name;
                });

                return itemObject;
            });

            dataLayer.push({
                ecommerce: null
            });

            dataLayer.push({
                event: "add_payment_info",
                ecommerce: {
                    currency: "GBP",
                    value: basket.Summary.SubTotalIncludingVAT,
                    payment_type: 'Pay by card',
                    items: items
                }
            });
        });
    }


    $('.js-select-payment-method').on('ready load change', function () {

        var method = $(this).val();

        $('.js-payment-method').hide();
        $('.js-payment-method[data-type=' + method + ']').show();

        var method_title = $(this).closest('.radio').find('.radio__text').text();

        getBasketData().then(function (basket) {
            var items = basket.Items.map(function (item, itemIndex) {
                // Create the base item object with common properties
                var itemObject = {
                    item_id: item.StockCode,
                    item_name: item.Name,
                    item_brand: item.BrandName,
                    price: item.PriceIncludingVATString,
                    quantity: item.Quantity
                };

                // Loop through each category in the item's Categories array
                item.Categories.forEach(function (category, categoryIndex) {
                    // Dynamically create the property name for categories
                    var propertyName = 'item_category' + (categoryIndex === 0 ? '' : (categoryIndex + 1));
                    // Assign the category name to the dynamically named property
                    itemObject[propertyName] = category.Name;
                });

                return itemObject;
            });

            dataLayer.push({
                ecommerce: null
            });

            dataLayer.push({
                event: "add_payment_info",
                ecommerce: {
                    currency: "GBP",
                    value: basket.Summary.SubTotalIncludingVAT,
                    payment_type: method_title,
                    items: items
                }
            });
        });

    });

    // "specify my details" - skip process, show delivery details form as normal
    $('.js-delivery-details').click(function (e) {
        e.preventDefault();
        $('.js-delivery-flow[section=postcode]').hide();
        $('.js-delivery-flow[section=details]').show();
        $('.js-address-postcode').removeClass("hidden");
        $('.js-select-address-wrapper, .js-select-text, .js-address-check-text').hide();
    });

    // show form on address select
    $('.js-address-select').on('change', function () {
        $('.js-delivery-flow[section=details]').show();
        $('.js-delivery-flow[section=details]').find('.checkout__sub-head-desc').hide();
        $('.js-select-text').hide();
        $('.js-details-text').show();
    });

    // delivery options are generated outside of Page DOM, so we call 'body' to ensure changes are tracked
    $('body').on('change', '.js-delivery-method', function () {
        $('.js-delivery-method-wrapper').removeClass('active');
        var container = $(this).closest('.js-delivery-method-wrapper');
        container.addClass('active');
        container.addClass('loading');
        $('body').addClass('setting-delivery');
        GetBasket();
    });

    $('.js-password-info').on('click', function () {
        dialogify('<p>For your safety, your password must...</p>' +
            '<ul>' +
            '<li>Contain at least one UPPERCASE letter</li>' +
            '<li>Contain at least one lowercase letter</li>' +
            '<li>Contain at least one number</li>' +
            '<li>Contain at least one of the following characters: ! " £ $ % ^ & * ( ) [ ] \'</li>' +
            '<li>At least 8 characters long</li>' +
            '<li>Measure Strong on the meter)</li>' +
            '</ul>');
    })

});
