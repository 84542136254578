function adjustRatios() {
    console.log('adjustRatios()');
    //Keep ratio of element on resize
    $('.js-keep-ratio').each(function () {

        var x = $(this).attr('data_x');
        var y = $(this).attr('data_y');

        var w = $(this).width();

        var h = (y / x) * w;

        $(this).height(h);
    })
}