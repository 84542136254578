$(document).ready(function () {

    $('body').on('change', '.js-cart-quantity', function () {
        var stockcode = $(this).attr('data-stockcode');
        UpdateBasketQuantity(stockcode, parseInt($(this).val()));
    });

});

function updateHeaderBasketTotal(text, total_exc_vat, total_inc_vat, lines) {

    console.log('updateHeaderBasketTotal');

    var amount = $('.js-basket-total-amount');

    if (text !== undefined) {
        text = decodeHtmlEntity(text);
        amount.text(text);

        if (text.length > 10) { /*Hides if more than 10 chars*/
            amount.addClass('hidden');
        } else {
            amount.removeClass('hidden');
        }
    }

    if (total_exc_vat !== undefined) {
        amount.attr('data-exc', total_exc_vat);
    }

    if (total_inc_vat !== undefined) {
        amount.attr('data-inc', total_inc_vat);
    }

    if (lines !== undefined) {
        amount.attr('data-lines', lines);
    }

    //Update header quantity
    var totalQuantity = 0;

    $('.js-minicart-item-quantity').each(function () {
        var quantity = $(this).val();
        totalQuantity += parseInt(quantity, 10); // Convert the value to a number using parseInt
    });

    if (totalQuantity > 99) {
        totalQuantity = '99+';
    }

    var header_qty = $('.js-header-quantity:visible');

    header_qty.text(totalQuantity);

    var string_length = header_qty.text().trim().length;

    if (string_length === 2) {
        header_qty.addClass('double-figures').removeClass('triple-figures');
    }

    if (string_length >= 3) {
        header_qty.addClass('triple-figures').removeClass('double-figures');
    }

    positionMinicartPointer();
}

function decodeHtmlEntity(textWithEntity) {
    var tempElement = document.createElement('div');
    tempElement.innerHTML = textWithEntity;
    return tempElement.textContent || tempElement.innerText;
}