$(document).ready(function () {

    $('body').on('focus click', '.js-quick-add-quantity', function () {
        if ($('.js-quick-add-quantity').val() == 1) {
            $('.js-quick-add-quantity').val('');
        }
    });

    $(window).on('resize orientationchange ready load change', function () {
        positionMinicartPointer();
    });

    $('.js-show-minicart').on('click', function (e) {
        $('.js-minicart').toggle();
        $('.js-minicart-overlay').toggle();
        positionMinicartPointer();

        if ($(window).width() < 770) {
            console.log('trigger');
            $('.js-quick-add-basket').select2('open');
            setTimeout(function () {
                $('.select2-search__field').focus();
            }, 50);
        }
    });

    $('body').on('click', '.js-close-minicart', function () {
        $('.js-minicart').hide();
        $('.js-minicart-overlay').hide();
    });

    $('body').on('click', function (e) {

        if ($(e.target).closest('.js-minicart, .js-show-minicart, .ui-dialog').length > 0) {
            //
        } else if ($(e.target).hasClass('js-minicart, js-show-minicart')) {
            //
        } else {
            if (!$(".select2-search__field").is(":visible")) {
                $('.js-minicart').hide();
                $('.js-minicart-overlay').hide();
            }
        }

    });

    $('body').on('change', '.js-minicart-item-quantity', function () {
        var ele = $(this);
        ChangeBasket(ele.data("stockcode"), ele.val());
    });

    positionMinicartPointer();
});

function positionMinicartPointer() {
    var viewport_width = $(window).width();
    var quick_add_link = $('.js-quick-add-link:visible');
    var point = $('.js-minicart-point');

    if (quick_add_link.length > 0 && point.length > 0) {
        var position = viewport_width - quick_add_link.offset().left;

        position = position - 20; //Cater for minicart positioning
        position = position - quick_add_link.width() / 2; //Make it middle of the link
        position = position - point.width() / 2; //Remove width of point itself
        position = position - 4; //Inexplicable adjustment

        point.css('right', position + 'px');
    }
}