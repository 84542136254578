$(document).ready(function () {

    $('body').on('change', '.js-postcode-search-filter', function () {


        if ($('.js-postcode-search-filter:checked').length == 0) {
            //Show all
            $('.js-postcode-search-result').slideDown();
        } else {
            $('.js-postcode-search-filter').each(function () {

                var ref = $(this).attr('data_ref');

                if ($(this).is(':checked')) {
                    $('.js-postcode-search-result[data_ref=' + ref + ']').slideDown();
                } else {
                    $('.js-postcode-search-result[data_ref=' + ref + ']').slideUp();
                }
            });
        }


    })

    $('body').on('click', '.js-clear-postcode-search-filter', function () {
        $('.js-postcode-search-result').slideDown();
    });

});