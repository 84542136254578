$(document).ready(function () {

    $.fn.checkRequiredFields = function () {
        console.log('checkRequiredFields()');
        var form = this;
        var empty_fields = [];
        var valid = true;
        var value = '';

        form.find('.js-required').each(function () {

            var placeholder = $(this).closest('label').find('span').first().text();

            if (!placeholder) {
                placeholder = $(this).attr('placeholder');
            }

            if ($(this).is('select')) {
                value = $(this).find(':selected').val();

                if (!placeholder) {
                    placeholder = $(this).find('option').first().text();
                }
            } else {
                value = $(this).val();
                value = value.trim();
            }

            if (!value && ((($(this).is(':visible') || $(this).attr('type') == "hidden") && $(this).parent().is(':visible')) || ($(this).hasClass('autocomplete') && $(this).parent().is(':visible'))) || (value == placeholder)) {
                if ($(this).attr('name') == 'files_container') {
                    wrapper = $(this).closest('div.file');

                    if (wrapper.find('ul > li').length == 0) {
                        valid = false;
                        empty_fields.push(placeholder);
                    }
                } else {
                    valid = false;
                    empty_fields.push(placeholder);
                }
            }

        });

        var response = {
            valid: valid,
            empty_fields: empty_fields
        };

        return response;
    };

    //Capture return key from forms
    $('form, .js-capture-return-key').keypress(function (e) {

        if (e.which == 13) {

            if (!$(e.target).hasClass('wysiwyg') && !$(e.target).is('textarea')) {
                $(this).find('button').not('.file button, .files button, .mce-tinymce button').trigger('click');
                return false;
            }

        }

    });

});

function isValidEmailAddress(emailAddress) {
    emailAddress = emailAddress.trim();
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
};